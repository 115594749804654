<template>
  <div>
    <div
      class="row mt-5 w-85 big-div"
      style="position: relative; z-index: 100"
      :style="
        getPageDesign() +
        `zoom:${this.pageZoomValue ? this.pageZoomValue : 90}%;`
      "
    >
      <div
        class="bg"
        v-if="getImageAlign == 'no'"
        :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
        :style="
          getbackgroundImage(
            IsImageAdded,
            pageDesign.imageBlock,
            getImageAlign,
            isDesktopView
          ) + getBgDivStyles()
        "
      ></div>
      <!-- Image Align Left -->
      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showLeftImage"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          :style="getImageOpacity(pageDesign.imageBlock.style)"
          :class="isDesktopView ? 'image-division' : ''"
        >
          <div :style="height100(isDesktopView)">
            <LazyImage
              :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) + getImageRotation(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock, isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="
                MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))
              "
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <!-- Main Section -->
      <div
        :class="!IsImageAdded ? 'col-12' : imageAddedLayout"
        class=" "
        :style="
          getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
          getScrollCSS(
            pageDesign.pageStyles,
            isDesktopView,
            pageDesign.imageBlock.style,
            pageDesign.pageType
          ) +
          calculateContentWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive,
            pageDesign.pageStyles
          )
        "
      >
        <div
          class=""
          style="
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
          "
        >
          <div style="overflow: hidden scroll" class="h-100">
            <div>
              <ProgressBar :order="CurrentPage.order" />
            </div>
            <!-- v-model="pageDesign.blocksArray" -->
            <draggable
              v-model="pageDesignBlocks"
              class="dragArea list-group"
              group="actions"
              v-bind="dragOptions"
              chosen-class="chosen"
              drag-class="drag"
              @start="dragging = true"
              @end="dragging = false"
              style="cursor: move"
            >
              <div
                v-for="(pro, ind) in pageDesignBlocks"
                :key="`${pro.type}-${ind}`"
                
                draggable="false"
                  :class="(dragging && pro.type != 'button') ? 'dragElementBorder' : null"
                  @drop="handleElementDrop($event,ind)"
                 
                  @dragover.prevent
                 @dragenter.prevent
                
                @click="changeCustomize(ind)"
              > 
             
                <div
                  :class="pro.type !== 'button' ? 'designBlockElement':null"
                  :style="getHeightWidth(pro.style) + getDivBorderCss(pro)"
                  style="position: relative"
                  :id="`${pro.type}-${ind}`"
                >
                <EditorAction v-if="pro.customizeStatus" :deleteCondition="getDeleteButtonCondition(pro)" :currElementIndex="ind" :currElementType="pro.type"/>
                  <div
                    v-if="pro.type == 'text'"
                    :style="
                      getColorCss(pro.style) +
                      getPaddingCss(pro.style) +
                      getMarginCss(pro.style, isDesktopView) +
                      getColorWithOpacity(pro.style) +
                      getTextRotate(pro.style) +
                      getLineHeight(pro.style) +
                      getTextAlign(pro.style) +
                      getFontStyle(pro.style,isDesktopView)
                    "
                    v-html="getEditorPersonalisedValueStyle(pro.content)"
                  ></div>
                  <div
                    v-if="pro.type == 'html'"
                    :style="
                      getMarginCss(pro.style, isDesktopView) +
                      getColorWithOpacity(pro.style) +
                      getTextRotate(pro.style) +
                      getTextAlign(pro.style)
                    "
                  >
                    <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
                  </div>
                  <!-- </VueDragResize> -->
                  <div v-if="pro.type == 'option'">
                    <div>
                      <span
                        class="m-2 d-inline-block"
                        v-if="radioInputEditMode"
                        style="cursor: pointer"
                      >
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                            fill="black"
                            fill-opacity="0.5"
                          />
                          <path
                            d="M10.2858 23.2593C10.2858 24.4 11.1429 25.3333 12.1905 25.3333H19.8096C20.8572 25.3333 21.7143 24.4 21.7143 23.2593V10.8148H10.2858V23.2593ZM12.1905 12.8889H19.8096V23.2593H12.1905V12.8889ZM19.3334 7.70369L18.381 6.66666H13.6191L12.6667 7.70369H9.33337V9.77777H22.6667V7.70369H19.3334Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <!-- Radio Options -->
                      <div
                        class=""
                        :style="
                          getMarginCss(pro.style, isDesktopView) +
                          getTextAlign(pro.style)
                        "
                      >
                        <div v-show="getCurrentSelectedOptionType === 'text'">
                          <!-- if option is not right aligned start -->
                          <div
                            v-if="pro.style.textAlign != 'end'"
                            :class="
                              getOptionAlignment == 'vertical'
                                ? 'd-flex flex-column '
                                : 'd-flex  flex-wrap '
                            "
                            :style="
                              getOptionAlignment == 'vertical'
                                ? null
                                : ` justify-content:${pro.style.textAlign}; `
                            "
                          >
                            <div
                              v-for="(radio, index) in getOptionsArr"
                              :key="index"
                              class="d-flex align-items-start"
                              :class="[
                                getOptionAlignment == 'horizontal'
                                  ? ' mr-2'
                                  : 'mb-1',
                                QuestionPageType == 'singleAnswer'
                                  ? 'radioboxDiv'
                                  : ' form-check checkboxDiv',
                              ]"
                              :style="
                                QuestionPageType == 'singleAnswer'
                                  ? selectedCheckboxColor(pro.style)
                                  : selectedCheckboxColor(pro.style)
                              "
                            >
                              <div
                                class="d-flex align-items-center w-100"
                                :style="`justify-content:${pro.style.textAlign};`"
                              >
                                <input
                                  :class="
                                    QuestionPageType == 'singleAnswer'
                                      ? 'mr-2 '
                                      : 'form-check-input'
                                  "
                                  :style="{
                                    height: pro.style.fontSize + 'px',
                                    width: pro.style.fontSize + 'px',
                                    position: 'relative',
                                  }"
                                  :type="
                                    QuestionPageType == 'singleAnswer'
                                      ? 'radio'
                                      : 'checkbox'
                                  "
                                  name="Inputs"
                                  class=""
                                  :id="`${CurrentPage.order}-${index}`"
                                  :value="
                                    QuestionPageType == 'singleAnswer'
                                      ? `${radio.value}`
                                      : `${radio}`
                                  "
                                />
                                <label
                                  :class="
                                    QuestionPageType == 'singleAnswer'
                                      ? ''
                                      : 'form-check-label'
                                  "
                                  class="m-0"
                                  :style="
                                    getColorCss(pro.style) +
                                    getColorWithOpacity(pro.style) +
                                    getFontStyle(pro.style)
                                  "
                                  :for="`${CurrentPage.order}-${index}`"
                                >
                                 <span v-html="radio.value"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <!-- if option is not right aligned end -->
                          <!-- if option is  right aligned start -->
                          <div
                            v-else
                            :class="
                              getOptionAlignment == 'vertical'
                                ? 'd-flex flex-column '
                                : 'd-flex  flex-wrap '
                            "
                            :style="
                             getOptionAlignment == 'vertical'
                                ? null
                                : ` justify-content:${pro.style.textAlign}; `
                            "
                          >
                            <div
                              v-for="(radio, index) in getOptionsArr"
                              :key="index"
                              class="d-flex align-items-start"
                              :class="[
                                getOptionAlignment == 'horizontal'
                                  ? 'mr-2'
                                  : 'mb-1',
                                QuestionPageType == 'singleAnswer'
                                  ? 'radioboxDiv'
                                  : ' form-check checkboxDiv',
                              ]"
                              :style="
                                QuestionPageType == 'singleAnswer'
                                  ? selectedCheckboxColor(pro.style)
                                  : selectedCheckboxColor(pro.style)
                              "
                            >
                              <div
                                class="d-flex align-items-center w-100"
                                :style="`justify-content:${pro.style.textAlign};`"
                              >
                                <label
                                  class="m-0"
                                  :class="
                                    QuestionPageType == 'singleAnswer'
                                      ? 'mr-2 '
                                      : 'form-check-label mr-4'
                                  "
                                  :style="
                                    getColorCss(pro.style) +
                                    getColorWithOpacity(pro.style) +
                                    getFontStyle(pro.style)
                                  "
                                  :for="`${CurrentPage.order}-${index}-right`"
                                >
                                <span v-html="radio.value"></span>
                                </label>
                                <input
                                  :class="
                                    QuestionPageType == 'singleAnswer'
                                      ? ''
                                      : 'form-check-input'
                                  "
                                  :style="{
                                    height: pro.style.fontSize + 'px',
                                    width: pro.style.fontSize + 'px',
                                    position: 'relative',
                                  }"
                                  :type="
                                    QuestionPageType == 'singleAnswer'
                                      ? 'radio'
                                      : 'checkbox'
                                  "
                                  name="Inputs"
                                  :id="`${CurrentPage.order}-${index}-right`"
                                  :value="
                                    QuestionPageType == 'singleAnswer'
                                      ? `${radio.value}`
                                      : `${radio}`
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <!-- if option is right aligned end -->
                        </div>
                        <div v-show="getCurrentSelectedOptionType === 'image'">
                          <div class="row m-0">
                            <div
                              v-for="(radio, index) in getOptionsArr"
                              :key="index"
                              class="d-flex flex-column"
                              :style="
                                getDivAlignmentStyle(pro.style.textAlign) +
                                getSpaceBetweenRow(pro.style)
                              "
                              :class="
                                isDesktopView
                                  ? `col-${12 / pro.OptionsPerRow}`
                                  : `col-${12 / pro.OptionsPerMobile}`
                              "
                            >

                            <div
                                :style="
                                  getImageHeightWidth(
                                    pro.style,
                                    isDesktopView
                                  ) +
                                  `${
                                    IsSelectedImageExist(radio)
                                      ? `border:2px solid ${
                                          pro.style.selectedImageIconColor ||
                                          '#000000'
                                        };padding:2px;`
                                      : null
                                  }`
                                "
                                class="ImageInputBlock position-relative"
                                @click="checkSelectedImage(radio)"
                              >
                                <LazyImage
                                  :src="
                                    ImageSrc(radio.image) ||
                                    `/images/image_placeholder.png`
                                  "
                                  alt=""
                                  :key="
                                    ImageSrc(radio.image) ||
                                    '/images/image_placeholder.png' + index
                                  "
                                  style="
                                    cursor: pointer;
                                    height: 100%;
                                    width: 100%;
                                  "
                                  :style="getBorderRadius(pro.style)"
                                />
                              </div>
                             
                              <div>
                             
                                <p
                                  class="m-0 p-0 mt-2"
                                  :style="
                                    getColorCss(pro.style) +
                                    getColorWithOpacity(pro.style) +
                                    getFontStyle(pro.style)
                                  "
                                  v-html="radio.value"
                                >
                                 
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-show="getCurrentSelectedOptionType === 'tab'">
                          <div v-if="getOptionAlignment == 'vertical'">
                            <ul
                              :style="
                               `max-width: ${isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;
                                list-style: none;
                                display: inline-block;`
                              "
                              class="m-0 p-0"
                            >
                              <li
                                v-for="(radio, index) in getOptionsArr"
                                :key="index"
                                class="mb-2"
                                :class="isDesktopView ? 'Tab' : ''"
                                :style="`min-width:${ isDesktopView ?  pro.style.defaultTab.minTabWidthDesktop || 200 : pro.style.defaultTab.minTabWidthMobile || 200 }px; max-width: ${isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;`"
                              >
                                <div>
                                  <button
                                    v-if="QuestionPageType == 'singleAnswer'"
                                    class="btn tab-btn border w-100"
                                    @click="
                                      updateSelectedAnswerRadio(radio.value)
                                    "
                                    :style="
                                      SelectedValue == radio.value
                                        ? getSelectedTabStyles(
                                            pro.style.selectedTab,
                                            pro.style
                                          )
                                        : getDefaultTabStyles(
                                            pro.style.defaultTab,
                                            pro.style
                                          )
                                    "
                                  >
                                    <span v-html="radio.value"></span>
                                  </button>
                                  <button
                                    v-else
                                    class="btn tab-btn border w-100"
                                    @click="updateSelectedAnswer(radio)"
                                    :style="
                                      isSelectedOptionExist(radio)
                                        ? getSelectedTabStyles(
                                            pro.style.selectedTab,
                                            pro.style
                                          )
                                        : getDefaultTabStyles(
                                            pro.style.defaultTab,
                                            pro.style
                                          )
                                    "
                                  >
                                  <span v-html="radio.value"></span>
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div v-else class="">
                            <div
                              class="m-0 p-0 row"
                              style="display: flex; flex-wrap: wrap"
                              :style="`height:100%;max-width: 100%; justify-content:${pro.style.textAlign};`"
                            >
                              <!-- class="col-6 col-sm-6 col-md-4 mb-2" -->
                              <div
                                v-for="(radio, index) in getOptionsArr"
                                :key="index"
                                style="flex-grow: 1"
                                class="mb-2"
                                :class="[
                                  isDesktopView ? 'Tab' : '',
                                  isDesktopView
                                    ? pro.optionSetting.horizontalOptionsPerRow
                                      ? `col-${
                                          12 /
                                          pro.optionSetting
                                            .horizontalOptionsPerRow
                                        }`
                                      : 'col-6 col-sm-6 col-md-4'
                                    : pro.optionSetting
                                        .horizontalOptionsPerRowMobile
                                    ? `col-${
                                        12 /
                                        pro.optionSetting
                                          .horizontalOptionsPerRowMobile
                                      }`
                                    : 'col-6 col-sm-6 col-md-4',
                                ]"
                              >
                                <div style="height: 100% !important">
                                  <button
                                    v-if="QuestionPageType == 'singleAnswer'"
                                    class="btn tab-btn border w-100"
                                    style="height: 100% !important"
                                    @click="
                                      updateSelectedAnswerRadio(radio.value)
                                    "
                                    :style="
                                      SelectedValue == radio.value
                                        ? getSelectedTabStyles(
                                            pro.style.selectedTab,
                                            pro.style
                                          )
                                        : getDefaultTabStyles(
                                            pro.style.defaultTab,
                                            pro.style
                                          )
                                    "
                                  >
                                  <span v-html="radio.value"></span>
                                  </button>
                                  <button
                                    v-else
                                    class="btn tab-btn border w-100"
                                    style="height: 100% !important"
                                    @click="updateSelectedAnswer(radio)"
                                    :style="
                                      isSelectedOptionExist(radio)
                                        ? getSelectedTabStyles(
                                            pro.style.selectedTab,
                                            pro.style
                                          )
                                        : getDefaultTabStyles(
                                            pro.style.defaultTab,
                                            pro.style
                                          )
                                    "
                                  >
                                  <span v-html="radio.value"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!--  Input Tab End -->
                        </div>
                        <div
                          v-show="getCurrentSelectedOptionType === 'dropdown'"
                        >
                          <!--  Dropdown Start -->
                          <div class="select-div h-100">
                            <multiselect
                              :options="getOptionsArr"
                              :multiple="false"
                              v-model="SelectedValue"
                              class="multiSelect"
                              placeholder="Select an option"
                              :style="
                                getColorCss(pro.style) +
                                getColorWithOpacity(pro.style) +
                                getFontStyle(pro.style) +
                                QuestionPageStyle(pro.style)
                              "
                              :close-on-select="false"
                              :searchable="false"
                              label="value"
                              track-by="value"
                            >
                            </multiselect>
                          </div>
                          <!--  Dropdown End -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="pro.type == 'tooltip'"
                    :style="
                      'display:flex;justify-content:' + pro.style.position + ';'
                    "
                  >
                    <button
                      :style="
                        getButtonCss(pro.style, isDesktopView) +
                        getCircularBtn(pro.isBtnRounded)
                      "
                      @mouseenter="pro.isTooltipVisible = true"
                      @mouseleave="pro.isTooltipVisible = false"
                    >
                      <span v-if="pro.useIcon">
                        <BIcon :icon="pro.selectedIcon" />
                      </span>

                      <span
                        v-else
                        :style="{
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        }"
                      >
                        {{ pro.text }}
                      </span>
                    </button>
                    <ToolTipComp
                      v-if="pro.isTooltipVisible"
                      :tooltipData="pro"
                      :isDesktopView="isDesktopView"
                    />
                  </div>
                  <div
v-if="pro.type == 'imageComp'"

>
<InlineImageComp :pro="pro" :isDesktopView="isDesktopView" />
</div>
                </div>
              </div>
            </draggable>
          </div>

          <!-- Static Button -->
          <div>
            <div
              @click="changeStaticBtnCustomisation(getButtonIndex)"
            >
              <div
                :style="
                  getHeightWidth(StaticButtonElement.style) +
                  getStaticButtonDivCss(staticButtonStyles)
                "
                class="designBlockElement"
              >
                <div class="w-100">
                  <div
                    class="d-flex"
                    :style="
                      getBackNextButtonPosition(
                        StaticButtonElement,
                        CurrentPage.value
                      )
                    "
                  >
                    <button
                      :style="
                        getCommonButtonCSS(
                          StaticButtonElement.style,
                          isDesktopView
                        ) +
                        getBackNextButtonCss(
                          StaticButtonElement.content.backButton.style,isDesktopView
                        )
                      "
                      v-if="
                        !StaticButtonElement.content.backButton.hideBackButton
                      "
                    >
                      <span
                        :style="{
                          opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                          fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                        }"
                        >{{ StaticButtonElement.content.backButton.text }}</span
                      >
                    </button>
                    <button
                      :style="
                        getCommonButtonCSS(
                          StaticButtonElement.style,
                          isDesktopView
                        ) +
                        getBackNextButtonCss(
                          StaticButtonElement.content.nextButton.style,isDesktopView
                        )
                      "
                      v-if="
                        !StaticButtonElement.content.nextButton.hideNextButton
                      "
                    >
                      <span
                        :style="{
                          opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                          fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                        }"
                        >{{ StaticButtonElement.content.nextButton.text }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Static Button End -->
        </div>
      </div>

      <!-- Image Align Right -->
      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showRightImage"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          :class="isDesktopView ? 'image-division' : ''"
        >
          <div :style="height100(isDesktopView)">
            <LazyImage
              :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) +
                getImageOpacity(pageDesign.imageBlock.style) +
                getImageRotation(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock, isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="
                MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))
              "
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import centralEventBus from "./centralEventBus";
import draggable from "vuedraggable";
import ProgressBar from "../customize/pages/ProgressBar.vue";
import cssFunctionMixin from "../../mixin/cssFunction";
import Multiselect from "vue-multiselect";
import { mapGetters, mapMutations } from "vuex";
import globalMixin from "../../../../GlobalMixins/getSignedURLMixin.js";
import EditorAction from "../../../../components/EditorActions.vue"

export default {
  props: ["isDesktopView"],

  mixins: [cssFunctionMixin, globalMixin],
  components: {
    ProgressBar,
    draggable,
    Multiselect,
    HtmlElement: () =>
      import(
        /* webpackChunkName: "HtmlElement" */ "../../../quizPreviewComp/HtmlElement.vue"
      ),
    ToolTipComp: () =>
      import(
        /* webpackChunkName: "ToolTipComp" */ "../../../quizPreviewComp/ToolTipComp.vue"
      ),
      EditorAction,
      InlineImageComp: () =>
      import(
        /* webpackChunkName: "InlineImageComp" */ "../../../quizPreviewComp/components/InlineImageComp.vue"
      ),
  },
  data() {
    return {
      editMode: false,
      RadioOptionDragging: false,
      RadioOptionImageDragging: false,
      dragging: false,
      radioInputEditMode: false,
      radioInputHoverMode: false,
      activeSideBar: "pageStyle",
      //   pageDesign: {},
      //   IsMasterQuestion: null,
      //   skip_status: null,
      count: 0,
      SelectedValue: "",
      selectedOptions: [],
      File: [],
      imageSrc: null,
      uploadingStatus: false,
      selectedImageInputIndex: null,
      //   QuestionPageType: null,

      staticButtonStyles: {
        hoverStatus: false,
        customizeStatus: false,
      },
    };
  },
  methods: {
    ...mapMutations(["setCurrSidebarView"]),
    handleElementDrop(evt , index){
      const item = evt.dataTransfer.getData('item')
      if(item ){
        let obj = {
          index:index,
          item:item,
          currPage:this.getSelectedPage.value
        }
        centralEventBus.$emit("handleAddElementDrop", obj);
        localStorage.setItem('DragStart',false)
      }
      
    },
    checkDefaultHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    DefaultBgColorOutput(value) {
      let isHex = this.checkDefaultHexCode(value);
      if (isHex) {
        this.showBgOpacity = true;
        return value;
      } else {
        this.showBgOpacity = false;
        return "Gradient input";
      }
    },
    checkSelectedtHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    SelectedBgColorOutput(value) {
      let isHex = this.checkSelectedtHexCode(value);
      if (isHex) {
        this.showBgOpacity = true;
        return value;
      } else {
        this.showBgOpacity = false;
        return "Gradient input";
      }
    },

    ImageSrc(img) {
      if(!img) return null
      let newImage;
      if (img.startsWith("/http")) {
        newImage = img.substring(1);
      } else {
        newImage = img;
      }
      if (
        newImage.indexOf("http://") === 0 ||
        newImage.indexOf("https://") === 0
      ) {
        return newImage;
      } else {
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        return baseURL + newImage.substring(1);
      }
    },

    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; height:" +
        this.pageDesign.pageStyles.height +
        "px;" +
        (this.getEditorPageView == "desktop"
          ? "width:" + this.pageDesign.pageStyles.width + "px;"
          : "width:350px;");

      return design;
    },
  async  changeCustomize(index) {
      if (this.staticButtonStyles.customizeStatus) {
        this.staticButtonStyles.customizeStatus = false;
      }
      await this.setCurrSidebarView('pageDesign')
      centralEventBus.$emit("changeCustomize", index);
    },

 async   changeStaticBtnCustomisation(index) {
      this.staticButtonStyles.customizeStatus = true;
      await this.setCurrSidebarView('pageDesign')
      centralEventBus.$emit("changeStaticCustomisation", index);
    },
    getDivBorderCss(block) {
      if (block.customizeStatus) return "border:2px solid #4065d5 !important ;";
      // else if (block.hoverStatus) return "border:2px solid #ada9a9";
      else return "border:2px solid transparent";
    },

    deleteBlock(index) {
      centralEventBus.$emit("deleteBlock", index);
    },
    async activeImageCustomize() {
await this.setCurrSidebarView('pageDesign')
centralEventBus.$emit("activeImageCustomize");
},


    addHoverOnImageBlock() {
      if (this.pageDesign.imageBlock.customizeStatus == true)
        this.pageDesign.imageBlock.hoverStatus = true;
    },
    removeHoverOnImageBlock() {
      this.pageDesign.imageBlock.hoverStatus = false;
    },
    deleteImage() {
      centralEventBus.$emit("deleteImage");
    },

    getDeleteButtonCondition(pro) {
      if (pro.customizeStatus) {
        if (pro.type == "text" && this.minTextCount <= 1) return false;
        if (pro.type == "option") return false;
        if (pro.type == "button") return false;
        return true;
      }
    },

    updateSelectedAnswerRadio(radioValue) {
      this.SelectedValue = radioValue;
    },
    checkSelectedImage(value) {
      if (this.QuestionPageType == "singleAnswer") {
        this.SelectedValue = value.value;
      } else {
        const index = this.selectedOptions.findIndex(
          (option) => option.id == value.id
        );
        if (index > -1) {
          this.selectedOptions.splice(index, 1);
        } else {
          this.selectedOptions.push(value);
        }
      }
    },
    IsSelectedImageExist(value) {
      if (this.QuestionPageType == "singleAnswer") {
        return this.SelectedValue == value.value;
      } else {
        const index = this.selectedOptions.findIndex(
          (option) => option.id == value.id
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    updateSelectedAnswer(value) {
      const index = this.selectedOptions.findIndex(
        (option) => option.id == value.id
      );
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      } else {
        this.selectedOptions.push(value);
      }
    },
    isSelectedOptionExist(value) {
      const index = this.selectedOptions.findIndex(
        (option) => option.id == value.id
      );
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        chosenClass: "custom-chosen",
      };
    },
    getButtonIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return index;
    },
    getImageAlign(){
      if (this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView) {
     return  this.pageDesign.pageStyles.mobileImageAlign ;
    } else {
      return this.pageDesign.pageStyles.imageAlign;
    }
    },

    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" ||
            this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getEditorPageView",
      "getProgressBar",
      "getPlanData",
      "getPageZoom",
    ]),
    pageZoomValue() {
      return this.getPageZoom;
    },
    getOptionAlignment(){

  const {useSeperateOptionAlignment , mobileTextOptionAlignment , textOptionAlignment} = this.pageDesign.blocksArray[this.getOptionIndex]

     return (useSeperateOptionAlignment && !this.isDesktopView && mobileTextOptionAlignment) 
    ? mobileTextOptionAlignment 
    : textOptionAlignment;
    },

    CurrentPage() {
      return this.getSelectedPage;
    },
    ProgressBarValues() {
      return this.getProgressBar;
    },
    staticButton() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index].staticButton;
    },

    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !==
          "" ||
        this.getUploadedImage
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-md-6 col-sm-12  col-12 col-xs-12";
    },

    pageDesign() {
      return this.getSelectedPage.pageDesign;
    },
    pageDesignBlocks: {
      get() {
        return this.pageDesign.blocksArray;
      },
      set(newValue) {
        centralEventBus.$emit("handleBlocksArrayDraggable", newValue);
      },
    },
    IsMasterQuestion() {
      return this.getSelectedPage.isMasterQuestion;
    },
    skip_status() {
      return this.getSelectedPage.skip_status;
    },
    QuestionPageType() {
      return this.getSelectedPage.value;
    },
    getOptionIndex() {
      let index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "option"
      );
      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },
    getCurrentSelectedOptionType() {
      if (this.isDesktopView) {
        return this.pageDesign.blocksArray[this.getOptionIndex].optionSetting.optionType;
      } else {
        return (
          this.pageDesign.blocksArray[this.getOptionIndex].optionSetting.mobileOptionsView ??
          this.pageDesign.blocksArray[this.getOptionIndex].optionSetting.optionType
        );
      }
    },
    getOptionsArr() {
      if (this.getOptionIndex > -1) {
        if (this.isOptionsShuffled) {
          const arrayCopy = [
            ...this.pageDesign.blocksArray[this.getOptionIndex].options,
          ];
          for (let i = arrayCopy.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
          }

          return arrayCopy;
        } else {
          return this.pageDesign.blocksArray[this.getOptionIndex].options;
        }
      }
      return [];
    },
    isOptionsShuffled() {
      return Object.prototype.hasOwnProperty.call(
        this.pageDesign.blocksArray[this.getOptionIndex],
        "shuffleOptions"
      )
        ? this.pageDesign.blocksArray[this.getOptionIndex].shuffleOptions
        : false;
    },
  },
};
</script>
<style scoped>
.designBlockElement:hover{
  border:2px solid #ada9a9 !important;
}
#imgInput:focus {
  outline: none !important;
}
.images-size label {
  color: #b3afb6;
}
.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  /* background:#ededee; */
  align-items: center;
}
.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}
.question-type-select {
  background-color: transparent;
  border: none;
  padding: 4px 39px !important;
  height: 36px;
  width: 100%;
}

.button-group {
  color: #535353;
  outline: none;
}
.button-selected {
  background: #000000 !important;
  color: #ffffff !important;
}
.text-img-btn-selected {
  background: #ffa201 !important;
  color: #ffffff !important;
}
.size-input {
  width: 60px;
  height: 20px;
  outline: #c4c4c4 solid 1px;
  border-radius: 4px;
}

.big-div {
  padding: 0px;
}

@media only screen and (max-width: 767px) {
  .big-div {
    width: 100% !important;
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 1366px) {
  .big-div {
    padding: 0px 15px !important;
  }
}

/* Button group CSS */
.btn-group {
  border: 1px solid #e0dee2;
  border-radius: 2px;
}
.btn-group .btn {
  border: none;
}
/* Modal CSS */
.modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}
.upload-div-single-answer {
  /* height: 200px; */
  height: 100%;
  text-align: center;
  background: #e0dee280;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}
.upload-div-single-answer i,
.upload-div-single-answer h4 {
  color: #6d6b6d;
}
.upload-div-single-answer:hover {
  background: rgba(77, 27, 126, 0.5);
}
.upload-div-single-answer:hover i,
.upload-div-single-answer:hover h4 {
  color: #ffffff;
}
.image-div {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}
.image-div img {
  position: absolute;
  left: 0;
}
.other-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.selected-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid #ffb201;
  cursor: pointer;
}
.selected-svg {
  height: 20px;
  width: 20px;
  background: #ffb201;
  border-radius: 100px;
  color: white;
  position: absolute;
  bottom: 6px;
  right: 10px;
}
.percent-text {
  position: absolute;
  font-size: 16px;
  bottom: 12px;
  font-weight: bold;
}

/* Custom select dropdown Css */
.custom-select-aanswer.result-select {
  border: none !important;
  width: 100%;
  padding-left: 0px;
}
.result-select {
  padding-left: 35px;
  background: #f4f3f5;
  height: inherit;
  border: none !important;
}
.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  /* background:#ededee; */
  align-items: center;
}
.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}

.select-div >>> .multiSelect {
  text-align: start;
  direction: ltr !important;
}
.select-div >>> .multiselect__content-wrapper {
  text-align: left !important;
  direction: ltr !important;
  z-index: 100;
  position: relative;
}
/* DropdownStyles */
.select-div >>> .multiselect .multiselect__option--highlight,
.select-div >>> .multiselect .multiselect__option--highlight:after {
  background: var(--dropdownHoverColor) !important;
}

.checkboxDiv input[type="checkbox"] {
  accent-color: var(--selectedCheckboxColor);
}
.radioboxDiv input[type="radio"] {
  accent-color: var(--selectedCheckboxColor);
}


</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>